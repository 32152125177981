import { ImageLoaderConfig } from "@angular/common";
import Base64 from 'crypto-js/enc-base64';
import hmacSHA256 from 'crypto-js/hmac-sha256';
import { environment } from "../../environments/environment";

const MEDIA_GALLERY_PREFIX = `${environment.ystmServiceBaseUrl}/storage/`;
const MEDIA_GALLERY_PREFIX_STAGING = `https://pd907d6b2.dip0.t-ipconnect.de/c107e3c2f1e7b61f8a54ef648b1d2ec8/services/V10/storage/`;

/**
 * Private helper to sign a path
 */
function sign(path: string, secret: string): string {

    // const hash = crypto.createHmac('sha256', secret)
    //     .update(path)
    //     .digest('base64')
    //     .slice(0, 40)
    //     .replace(/\+/g, '-').replace(/\//g, '_');
    //const hash = CryptoJS.HmacSHA56(path, secret);
    const hash = Base64.stringify(hmacSHA256(path, secret)).slice(0, 40).replace(/\+/g, '-').replace(/\//g, '_');
    // console.log('path: ', path);
    // console.log('hash: ', hash);
    return hash + '/' + path
}

// /**
//  * Private helper to sign a pass with HMAC-SHA256 using  Web Crypto API
//  * - Concept: https://stackoverflow.com/questions/47329132/how-to-get-hmac-with-crypto-web-api
//  * the concept can not be deployed, since ngOptimizedImage does not support async image loader functions
//  * @param path the path to sign
//  * @param secret the secret to use
//  * @returns {Observable<string>}
//  */
// function signModern(path, secret): Observable<string> {
//     const encoder = new TextEncoder();

//     return from(crypto.subtle.importKey(
//         "raw", // raw format of the key - should be Uint8Array
//         encoder.encode(secret),
//         { // algorithm details
//             name: "HMAC",
//             hash: { name: "SHA-512" }
//         },
//         false, // export = false
//         ["sign", "verify"] // what this key can do
//     )).pipe(
//         concatMap(key => from(crypto.subtle.sign(
//             "HMAC",
//             key,
//             encoder.encode(path)
//         ))),
//         map(signature => {
//              /** convert buffer to byte array, see https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest */
//              const hashArray = Array.from(new Uint8Array(signature));
//              const hashHex = hashArray.map(b => b.toString(16).padStart(2, "0")).join('');
//              const hash = hashHex.slice(0, 40).replace(/\+/g, '-').replace(/\//g, '_');
//              return hash + '/' + path;
//         })
//     );
// }

/**
 * Represents an image loader function. Image loader functions are used by the
 * NgOptimizedImage directive and other application parts to produce full image URL based on the image name and its width.
 * - See also: https://developer.chrome.com/blog/angular_ngoptimizedimage?hl=en
 * @param config - Image loader configuration.
 * @returns Full image URL.
 */
export function imageLoader(config: ImageLoaderConfig): string {
    // console.log ('config: ',    config);
    // console.log('environment.line: ', environment.line);
    //console.log('config.src: ', config.src);
    // console.log('config.width: ', config.width);
    //console.log ('config.loaderParams: ', config.loaderParams)
    // if (config.src.endsWith('sunset2.jpg') || config.src.endsWith('catwalk1.jpg')) {
    //     console.log('config: ', config);
    // }
    // const src = config.src;
    //const widthParam = config.width ? `fit-in/${config.width}x/` : undefined;
    const widthParam = getWidthParam(config);
    const filterParam = (config.loaderParams && config.loaderParams['filters']) ? `filters:${config.loaderParams['filters']}/` : undefined;
    switch (environment.line) {


        case 'development':
            // return config.width ? `http://localhost:6914/unsafe/fit-in/${config.width}x/http://172.28.67.139:8080/${config.src}` : `${config.src}`;
            {
                let srcUrl = `http://tahaa:6912/${config.src}`;
                if (config.loaderParams && config.loaderParams['repo'] === 'MEDIA_GALLERY') {
                    srcUrl = `${MEDIA_GALLERY_PREFIX_STAGING}${config.src}`;
                }
                const signedPath = sign(`${widthParam ?? ''}${filterParam ?? ''}${srcUrl}`, 'catchoftheday');
                // if (config.src.endsWith('sunset2.jpg') || config.src.endsWith('catwalk1.jpg')) {
                //     console.log('signedPath: ', signedPath);
                // }
                // return config.width ? `https://pd907d6b2.dip0.t-ipconnect.de/5451e65651e09c5c35a761161d2bd55c/${signedPath}` : `${config.src}`;
                const res = `https://pd907d6b2.dip0.t-ipconnect.de/5451e65651e09c5c35a761161d2bd55c/${signedPath}`;
                // console.log ('config: ', config, 'res: ', res);
                return res;
            }
        case 'staging':
            // case 'development':
            /** unsafe mode */
            //return config.width ? `https://pd907d6b2.dip0.t-ipconnect.de/5451e65651e09c5c35a761161d2bd55c/unsafe/fit-in/${config.width}x/http://tahaa:6912/${config.src}` : `${config.src}`;
            /** with signing */
            {
                let srcUrl = `http://tahaa:6912/${config.src}`;
                if (config.loaderParams && config.loaderParams['repo'] === 'MEDIA_GALLERY') {
                    srcUrl = `${MEDIA_GALLERY_PREFIX}${config.src}`;
                }
                //const signedPath = sign(`fit-in/${config.width}x/http://tahaa:6912/${config.src}`, 'catchoftheday');
                const signedPath = sign(`${widthParam ?? ''}${filterParam ?? ''}${srcUrl}`, 'catchoftheday');
                // console.log('signedPath: ', signedPath);
                //const res = config.width ? `https://pd907d6b2.dip0.t-ipconnect.de/5451e65651e09c5c35a761161d2bd55c/${signedPath}` : `${config.src}`;
                const res = `https://pd907d6b2.dip0.t-ipconnect.de/5451e65651e09c5c35a761161d2bd55c/${signedPath}`;
                // console.log('res: ', res);
                return res;
            }
        case 'production':
        default:
            // return config.width ? `http://tahaa:6914/unsafe/fit-in/${config.width}x/https://ystm.eu/${config.src}` : `${config.src}`;
            {
                let srcUrl = `https://ystm.eu/${config.src}`;
                if (config.loaderParams && config.loaderParams['repo'] === 'MEDIA_GALLERY') {
                    srcUrl = `${MEDIA_GALLERY_PREFIX}${config.src}`;
                }
                const signedPath = sign(`${widthParam ?? ''}${filterParam ?? ''}${srcUrl}`, 'catchoftheday');
                // const signedPath = sign(`fit-in/${config.width}x/https://ystm.eu/${config.src}`, 'catchoftheday');
                // console.log('signedPath: ', signedPath);
                //return config.width ? `https://yachting-service.de/5451e65651e09c5c35a761161d2bd55c/${signedPath}` : `${config.src}`;
                return `https://yachting-service.de/5451e65651e09c5c35a761161d2bd55c/${signedPath}`;
            }
        // return `${config.src}`;
    }
}

function getWidthParam(config: ImageLoaderConfig): string | undefined {
    if (config.width) {
        return `fit-in/${config.width}x/`;
    } else {
        /** difficult scenario, for now we return viewportWidth resolved image */
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        return `fit-in/${viewportWidth}x/`;
    }
}

// /**
//  * generate preload instructions to be placed in index.html
//  * - logs link preload tags to console
//  * - logs image tags to console
//  * @param config is the image loader config
//  *
//  * Status: not working yet
//  */
// export function imageLoaderPreloadInstructions(config: ImageLoaderConfig): void {
//     /** image config providing all breakpoints as options.breakpoints */
//     const options: ImageConfig = inject(IMAGE_CONFIG);

// }